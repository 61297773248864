{
  "id": "yeast",
  "name": "Gramene Yeast",
  "description": "Pan genome browser for yeast varieties",
  "version": "v1",
  "url": "https://yeast.gramene.org",
  "ensemblURL": "https://yeast-ensembl.gramene.org",
  "ensemblSite": "https://yeast-ensembl.gramene.org",
  "ensemblRest": "https://data.gramene.org/pansite-ensembl-87",
  "grameneData": "https://data.gramene.org/yeast_v1",
  "targetTaxonId": 3702,
  "portals": ['browser','pansites'],
  "portals2": ['pansites','browser','reactome','climtools','atlas','trackhub'],
  "showNews": false,
  "showGuides": false,
  "showFeed": false,
  "showTweets": false,
  "showInMenu": false,
  "date": "2022-03-30",
  "slides": []
}
