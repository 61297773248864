{
  "id": "sorghum",
  "name": "Sorghumbase",
  "version": "v6",
  "description": "Genomic resources for the sorghum research community",
  "url": "https://www.sorghumbase.org",
  "ensemblURL": "https://ensembl-dev.sorghumbase.org",
  "ensemblSite": "https://ensembl-dev.sorghumbase.org",
  "ensemblRest": "https://data.gramene.org/pansite-ensembl-87",
  "grameneData": "https://data.sorghumbase.org/sorghum_v6",
  "targetTaxonId": 4588,
  "curation": {
    "url": "http://curate.gramene.org/sorghum_v6?since=4479&gene=",
    "taxa": {
      "4558": 1,
      "4558001": 1
    }
  },
  "alertText": "Click the search icon in the menu bar or type / to search",
  "portals": ['browser','blast','pansites','curated'],
  "portals2": ['pansites','browser','reactome','climtools','blast','atlas','trackhub'],
  "showInMenu": true,
  "showNews": true,
  "showGuides": true,
  "showFeed": true,
  "showTweets": true,
  "slides": [],
  "panSite" : {
    vitis_vinifera : {
      url: "https://vitis.gramene.org?idList=",
      name: "Gramene Grapevine",
      svg: "./static/images/grapevine_logo.svg"
    },
    oryza_sativa : {
      url: "https://oryza.gramene.org?idList=",
      name: "Gramene Oryza",
      svg: "./static/images/oryza_logo.svg"
    },
    zea_maysb73 : {
      url: "https://maize-pangenome.gramene.org?idList=",
      name: "Gramene Maize",
      svg: "./static/images/maize_logo.svg"
    }
  }
}